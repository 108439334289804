import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";

Vue.use(VueResource);
/*
public methods:
fetch - request a list of items

*/
const baseurl = ""; //v1  set to "" after conversion
export default class EventLogService {
  async fetch(query, URL) {
    let self = this;
    return new Promise((resolve) => {
      let url = URL || `${baseurl}system_history/?format=json`;
      let auth = new Auth();
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            // begin_test_block
            // response.body.next_page = url;
            // response.body.count = 105;
            // response.body.results = response.body.results.map((item) => {
            //   item.system_event_id = item.system_event.id;
            //   item.system_sub_event_id = item.system_sub_event.id;
            //   delete item.system_event;
            //   delete item.system_sub_event;
            //   return item;
            // });
            // end_test_block
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }
}
